<template>
  <section class="pb-2">
    <b-card>
      <template #header>
        <div class="d-flex align-items-center">
          <span class="text-primary">Parco Auto </span>
          <b-breadcrumb class="ml-2">
            <b-breadcrumb-item>
              <feather-icon icon="HomeIcon" scale="1.25" shift-v="1.25"></feather-icon>
            </b-breadcrumb-item>
            <b-breadcrumb-item active> In vendita</b-breadcrumb-item>
          </b-breadcrumb>
        </div>
      </template>

      <b-row class="mt-2">
        <b-col md="9"> </b-col>

        <b-col md="3">
          <div class="d-flex align-items-center mb-2">
            <span class="mr-1">Cerca</span>
            <b-form-input type="text" v-model="filter" size="md" debounce="500" />
          </div>
        </b-col>
      </b-row>

      <b-table :fields="fields" :items="carProvider" :busy="loading" :current-page="currentPage" :filter="filter"
        striped responsive show-empty @sort-changed="sortTable">
        <template #table-busy>
          <div class="text-center text-primary-light my-2">
            <b-spinner class="align-middle mr-2"></b-spinner>
            <strong>Caricamento dati delle auto...</strong>
          </div>
        </template>
        <template #empty>
          <div class="text-center">
            <h5 class="text-primary-light my-2">
              Non ci sono auto da mostrare
            </h5>
          </div>
        </template>
        <template v-slot:head(selected)>
          <input type="checkbox" />
        </template>
        <template v-slot:cell(selected)="row">
          <b-form-group>
            <input type="checkbox" v-model="row.item.selected" />
          </b-form-group>
        </template>
        <template #cell(opzioni)="row">
          <span class="text-nowrap mr-2">
            <b-dropdown variant="link" toggle-class="text-decoration-none" no-caret>
              <template #button-content>
                <feather-icon style="color: gray" size="20" icon="LayersIcon"></feather-icon>
              </template>
              <b-dropdown-item @click="getAutoPhotos(row.item)">Foto</b-dropdown-item>
              <b-dropdown-item @click="getAutoExaminationPhotos(row.item)">Foto Perizia</b-dropdown-item>
              <b-dropdown-item v-b-modal.vehicle_details_modal>Dettagli Veicolo</b-dropdown-item>
              <b-dropdown-item>Documenti Associati</b-dropdown-item>
            </b-dropdown>

            <b-dropdown class="mx-2" size="sm" variant="link" toggle-class="text-decoration-none" no-caret>
              <template #button-content>
                <feather-icon style="color: gray" size="20" icon="EditIcon"></feather-icon>
              </template>
              <b-dropdown-item>Modifica Foto</b-dropdown-item>
              <b-dropdown-item>Modifica Foto Perizia</b-dropdown-item>
              <b-dropdown-item>Modifica Dettagli Auto</b-dropdown-item>
              <b-dropdown-item>Modifica Documenti Associati</b-dropdown-item>
              <b-dropdown-item>Modifica Costi</b-dropdown-item>
              <b-dropdown-item>Modifica Optionals</b-dropdown-item>
              <b-dropdown-item>Modifica Prezzi</b-dropdown-item>
              <b-dropdown-item>Modifica Status</b-dropdown-item>
              <b-dropdown-item>Modifica Ubicazione</b-dropdown-item>
            </b-dropdown>

            <b-dropdown size="sm" variant="link" toggle-class="text-decoration-none" no-caret>
              <template #button-content>
                <feather-icon style="color: gray" size="20" icon="FileTextIcon"></feather-icon>
              </template>
              <b-dropdown-item>Stampa Scheda</b-dropdown-item>
              <b-dropdown-item>Elenco Prenotazioni</b-dropdown-item>
              <b-dropdown-item>Gestione Pratiche</b-dropdown-item>
              <b-dropdown-item>Gestione Finanziamenti</b-dropdown-item>
            </b-dropdown>
          </span>
        </template>
      </b-table>

      <b-pagination v-model="currentPage" :per-page="perPage" :total-rows="items.count" align="right" size="sm"
        class="my-0" />
    </b-card>

    <!-- Modali -->

    <!-- Modale Modifica Prezzi -->

    <b-modal id="price_modal" ref="price_modal" title="Modifica Prezzi" ok-only ok-title="Salva Modifiche">
      <div class="py-2 px-1">
        <b-form>
          <b-form-group label="Prezzo di vendita al privato">
            <b-form-input type="number" placeholder="Specifica il nuovo prezzo per la vendita al privato">
            </b-form-input>
          </b-form-group>

          <b-form-group label="Prezzo da mostrare sul sito e portali web">
            <b-form-input type="number" placeholder="Specifica il nuovo prezzo per la vendita sui portali web">
            </b-form-input>
          </b-form-group>

          <b-form-group label="Prezzo di vendita al privato">
            <b-form-input type="number" placeholder="Specifica il nuovo prezzo per la vendita al rivenditore">
            </b-form-input>
          </b-form-group>
        </b-form>
      </div>
    </b-modal>

    <!-- Modale Foto Veicolo -->

    <b-modal id="vehicle_photos_modal" title="Foto veicolo" size="lg"
      @hide="fotoAuto = []">
      <div class="py-2 px-1">
        <p v-if="fotoAuto.length == 0">Nessuna foto presente</p>
        <swiper v-if="fotoAuto.length > 0" class="swiper-gallery gallery-top"
          :options="fotoOptions">
          <swiper-slide v-for="data in fotoAuto" :key="data">
            <b-img :src="data" fluid />
          </swiper-slide>

          <div slot="button-next" class="swiper-button-next swiper-button-white" />
          <div slot="button-prev" class="swiper-button-prev swiper-button-white" />
        </swiper>

        <swiper v-if="fotoAuto.length > 0" class="swiper gallery-thumbs mt-1"
          :options="fotoOptionThumbs">
          <swiper-slide v-for="data in fotoAuto" :key="data">
            <b-img :src="data" fluid />
          </swiper-slide>
        </swiper>
      </div>
      <template #modal-footer="{ ok, cancel, hide }">
        <b-button variant="secondary">Scarica tutte le foto</b-button>
        <b-button variant="primary" @click="ok()">Chiudi</b-button>
      </template>
    </b-modal>

    <!-- Modale Foto Perizia -->

    <b-modal id="vehicle_examination_modal" title="Foto perizia veicolo" size="lg" @hide="fotoPeriziaAuto = []">
      <div class="py-2 px-1">
        <p v-if="fotoPeriziaAuto.length == 0">Nessuna foto perizia presente</p>
        <swiper v-if="fotoPeriziaAuto.length > 0" class="swiper-gallery gallery-top"
          :options="fotoPeriziaOptions">
          <swiper-slide v-for="data in fotoPeriziaAuto" :key="data">
            <b-img :src="data" fluid />
          </swiper-slide>

          <div slot="button-next" class="swiper-button-next swiper-button-white" />
          <div slot="button-prev" class="swiper-button-prev swiper-button-white" />
        </swiper>

        <swiper v-if="fotoPeriziaAuto.length > 0" class="swiper gallery-thumbs mt-1"
          :options="fotoPeriziaOptionThumbs">
          <swiper-slide v-for="data in fotoPeriziaAuto" :key="data">
            <b-img :src="data" fluid />
          </swiper-slide>
        </swiper>
      </div>
      <template #modal-footer="{ ok, cancel, hide }">
        <b-button variant="secondary">Scarica tutte le foto</b-button>
        <b-button variant="primary" @click="ok()">Chiudi</b-button>
      </template>
    </b-modal>

    <!-- Dettagli Veicolo -->

    <b-modal id="vehicle_details_modal" ref="vehicle_details_modal" title="Dettagli veicolo" ok-only ok-title="Ok"
      size="lg">
      <div class="py-2 px-1">
        <h2 class="text-primary">Alfa Romeo Giulietta</h2>
        <p class="text-primary">xxxxxxx</p>

        <b-row>
          <b-col class="border border-right" md="4">
            <b-row>
              <b-col class="d-flex" md="6">
                <feather-icon class="text-primary mr-1" icon="StarIcon"></feather-icon>
                <div>
                  <span class="text-primary-light font-weight-bold">Tipologia:
                  </span>
                  <p>Usato</p>
                </div>
              </b-col>

              <b-col md="6">
                <feather-icon icon="StarIcon"></feather-icon>
                <span class="text-primary-light font-weight-bold">Marca: </span>
                <p>Usato</p>
              </b-col>
            </b-row>
          </b-col>

          <b-col md="8">
            <b-row>
              <b-col md="6">
                <feather-icon icon="StarIcon"></feather-icon>
                <span class="text-primary-light font-weight-bold">Tipologia:
                </span>
                <p>Usato</p>
              </b-col>
            </b-row>
          </b-col>
        </b-row>
      </div>
    </b-modal>
  </section>
</template>

<script>
import {
  BRow,
  BCol,
  BButton,
  BBreadcrumb,
  BBreadcrumbItem,
  BCard,
  BCardTitle,
  BIcon,
  BImg,
  BDropdown,
  BDropdownItem,
  BTable,
  BModal,
  BForm,
  BFormInput,
  BFormGroup,
  BFormCheckbox,
  BSpinner,
  BPagination,
} from "bootstrap-vue";
import { Swiper, SwiperSlide } from "vue-awesome-swiper";
import "swiper/css/swiper.css";

import { Requests } from "@/api";
import { Swal } from "sweetalert2";

export default {
  components: {
    BRow,
    BCol,
    BButton,
    BBreadcrumb,
    BBreadcrumbItem,
    BCard,
    BCardTitle,
    BIcon,
    BImg,
    BDropdown,
    BDropdownItem,
    BTable,
    BModal,
    BForm,
    BFormInput,
    BFormGroup,
    BFormCheckbox,
    BSpinner,
    BPagination,
    Swiper,
    SwiperSlide,
  },

  data: () => ({
    fields: [
      { key: "selected", sortable: false },
      { key: "marca", sortable: true },
      { key: "modello", sortable: true },
      { key: "versione", sortable: true },
      { key: "immatricolazione_anno", label: 'anno', sortable: true },
      { key: "chilometraggio", label: 'km', sortable: true },
      { key: "targa", sortable: true },
      { key: "telaio", sortable: true },
      { key: "colore", sortable: true },
      { key: "opzioni", sortable: false }
    ],

    items: [],
    filter: null,
    perPage: 15,
    currentPage: 1,
    sortBy: "marca",
    sortDesc: false,

    fotoAuto: [require("@/assets/images/logo.png")],
    fotoPeriziaAuto: [require("@/assets/images/logo.png")],

    fotoOptions: {
      loop: true,
      loopedSlides: 5,
      spaceBetween: 10,
      navigation: {
        nextEl: ".swiper-button-next",
        prevEl: ".swiper-button-prev",
      },
    },

    fotoOptionThumbs: {
      loop: true,
      loopedSlides: 5, // looped slides should be the same
      spaceBetween: 10,
      centeredSlides: true,
      slidesPerView: 4,
      touchRatio: 0.2,
      slideToClickedSlide: true,
    },

    fotoPeriziaOptions: {
      loop: true,
      loopedSlides: 5,
      spaceBetween: 10,
      navigation: {
        nextEl: ".swiper-button-next",
        prevEl: ".swiper-button-prev",
      },
    },

    fotoPeriziaOptionThumbs: {
      loop: true,
      loopedSlides: 5, // looped slides should be the same
      spaceBetween: 10,
      centeredSlides: true,
      slidesPerView: 4,
      touchRatio: 0.2,
      slideToClickedSlide: true,
    },
    //Helpers
    loading: false,
  }),


  methods: {
    async getAutoPhotos(auto) {
      let request_data = new FormData();

      request_data.append("id_auto", auto.id_auto);
      try {
        const res = await Requests.getAutoPhotos(request_data);
        this.fotoAuto = res.map(foto => foto.image_path);
        this.$bvModal.show('vehicle_photos_modal');
      } catch (err) {
        Swal.fire('Attenzione', 'Non è stato possibile caricare le immagini', 'error');
      }

    },

    async getAutoExaminationPhotos(auto) {
      let request_data = new FormData();

      request_data.append("id_auto", auto.id_auto);

      try {
        const res = await Requests.getAutoExaminationPhotos(request_data);
        this.fotoPeriziaAuto = res.map(foto => foto.image_path);
        this.$bvModal.show('vehicle_examination_modal');
      } catch (err) {
        Swal.fire('Attenzione', 'Non è stato possibile caricare le immagini', 'error');
      }

    },

    async getAutoOnSale(page) {
      this.loading = true;

      page = page ?? this.currentPage;

      let request_data = new FormData();

      request_data.append("offset", (page - 1) * this.perPage);
      request_data.append("limit", this.perPage);
      request_data.append("filter", this.filter);
      request_data.append("sortBy", this.sortBy);
      request_data.append("sortDesc", this.sortDesc);

      try {
        const response = await Requests.getAutoOnSale(request_data);
        this.items = response;
      } catch (err) {
        Swal.fire('Attenzione', 'Non è stato possibile caricare le auto', 'error');
      }

      this.loading = false;
    },

    async carProvider() {
      await this.getAutoOnSale();
      return this.items.data;
    },

    async sortTable(ctx) {
      this.sortBy = ctx.sortBy;
      this.sortDesc = ctx.sortDesc;
    },
  },
};
</script>

<style>
.b-dropdown button {
  padding: 0;
}
</style>
